import React, { useState } from 'react'

import { Carousel, Container, Row, Col } from 'react-bootstrap';

import Image from '../image';

export default () => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    
    return (
        <>
            <style type="text/css">
            {`
                .dark-bg-hero-carousel {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.4);
                    z-index: 2;
                }
                .carousel {
                    max-height: 100vh;
                    overflow: hidden;
                }
                .carousel-wrapper {
                    position: relative;
                    max-height: 100vh;
                    overflow: hidden;
                }
                .hero-text {
                    z-index: 3;
                    top: 35%;
                    bottom: unset;
                    right: 0;
                    left: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                    color: #fff;
                    position: absolute;
                }
                .carousel-title {
                    font-family: 'Nothing You Could Do', cursive;
                    font-size: 40px;
                    font-weight: bold;
                    text-shadow: 0px 1px #000;
                }
                .carousel-title span {
                    font-weight: 700;
                    color: #3FBF5C;
                }
                .carousel-description {
                    font-size: 30px;
                    text-shadow: 0px 1px #000;
                    font-weight: 700;
                }
                .carousel-div-title {
                    border: dashed 1px #fff;
                    display: inline-block;
                    font-size: 30px;
                    margin-bottom: 20px;
                    padding: 0 15px;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-style: italic;
                }
                .carousel-indicators li {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    margin-right: 6px;
                    margin-left: 6px;
                }
                .carousel-control-prev, .carousel-control-next {
                    z-index: 3;
                    opacity: 0.9;
                }
                .carousel-fade1 .carousel-item {
                    opacity: 0;
                    transition-duration: .6s;
                    transition-property: opacity;
                }
                
                .carousel-fade1  .carousel-item.active,
                .carousel-fade1  .carousel-item-next.carousel-item-left,
                .carousel-fade1  .carousel-item-prev.carousel-item-right {
                    opacity: 1;
                }
                
                .carousel-fade1 .active.carousel-item-left,
                .carousel-fade1  .active.carousel-item-right {
                    opacity: 0;
                }
                
                .carousel-fade1  .carousel-item-next,
                .carousel-fade1 .carousel-item-prev,
                .carousel-fade1 .carousel-item.active,
                .carousel-fade1 .active.carousel-item-left,
                .carousel-fade1  .active.carousel-item-prev {
                    transform: translateX(0);
                    transform: translate3d(0, 0, 0);
                }
                @media (min-width: 768px) and (max-width: 939px) {
                    .carousel-description {
                        font-size: 30px;
                    }
                    .carousel-div-title {
                        font-size: 22px;
                    }
                }
                @media(max-width: 767px) {
                    .hero-text {
                        top: 25%;
                    }
                    .carousel-title {
                        font-size: 25px;
                    }
                    .carousel-description {
                        font-size: 20px;
                    }
                    .w-100 {
                        width: 200% !important;
                        margin-left: -50%;
                    }
                    .carousel-div-title {
                        font-size: 18px;
                    }
                }
            `}
            </style>
            <div className="carousel-wrapper">
            <div className="dark-bg-hero-carousel"></div>
            <div className="hero-text">
                <Container>
                    <Row>
                        <Col className="text-center">
                            <div className="carousel-div-title">We are open for dine in!</div>
                            <h3 className="carousel-title">WELCOME TO <span>Sumo Japanese Restaurant</span></h3>
                            <p className="carousel-description">JAPANESE RESTAURANT {`&`} SUSHI BAR</p>
                            <a style={{lineHeight: '1.5'}} className="btn btn-primary btn-lg btn-navbar-custom mt-3" target="_blank" rel="noreferrer" href="https://www.beyondmenu.com/48111/albany/sumo-sushi-restaurant-albany-94706.aspx">Online order<br />Pick up {`&`} Delivery</a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Carousel interval={10000} controls={true} activeIndex={index} onSelect={handleSelect} className="carousel-fade1">
                <Carousel.Item>
                    <Image src="slider1.jpg" alt="slider1 Sumo Japanese Restaurant" className="d-block w-100" />
                </Carousel.Item>
                <Carousel.Item>
                    <Image src="slider2.jpg" alt="slider2 Sumo Japanese Restaurant" className="d-block w-100" />
                </Carousel.Item>
                <Carousel.Item>
                    <Image src="slider3.jpg" alt="slider3 Sumo Japanese Restaurant" className="d-block w-100" />
                </Carousel.Item>
            </Carousel>
            </div>
        </>
    )
}
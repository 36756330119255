import React from 'react'
import { Link } from 'gatsby'
import { Jumbotron } from 'react-bootstrap'
import SEO from '../components/seo'
import Layout from '../components/layout'

import HeroCarousel from '../components/views/hero-carousel'
/*import Events from '../components/views/events'*/
import Gallery from '../components/views/gallery'


export default function Home() {
  return (
    <>
    <style>{`
      .jumptron-title-c {
        font-family: 'Nothing You Could Do', cursive;
        font-size: 40px;
        font-weight: bold;
        text-shadow: 0px 1px #000;
      }
    `}</style> 
    <Layout>
      <SEO title="Home" />
      <HeroCarousel />

      <Jumbotron className="text-center bg-light">
        <h1 className="jumptron-title-c">Sumo Sushi Albany</h1>
        <p>
          Sumo Sushi Restaurant offers delicious dining and takeout to Albany, CA.
        </p>
        <p>
          <Link className="btn btn-primary btn-lg btn-navbar-custom mt-3" to={`/about`}>Learn more</Link>
        </p>
      </Jumbotron>
      
      {/*<Events />*/}
      <div style={{marginTop: '50px'}}></div>
      <Gallery />
    </Layout>
  </>)
}

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Gallery from 'react-grid-gallery';

const IMAGES =
[
{
    src: "/img/gallery/gallery1.jpg",
    thumbnail: "/img/gallery/gallery1.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 300
},
{
    src: "/img/gallery/gallery2.jpg",
    thumbnail: "/img/gallery/gallery2.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 300
},
{
    src: "/img/gallery/gallery3.jpg",
    thumbnail: "/img/gallery/gallery3.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 300
},
{
    src: "/img/gallery/gallery4.jpg",
    thumbnail: "/img/gallery/gallery4.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 300
},
{
    src: "/img/gallery/gallery5.jpg",
    thumbnail: "/img/gallery/gallery5.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 300
},
{
    src: "/img/gallery/gallery6.jpg",
    thumbnail: "/img/gallery/gallery6.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 300
},
{
    src: "/img/gallery/gallery7.jpg",
    thumbnail: "/img/gallery/gallery7.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 300
},
{
    src: "/img/gallery/gallery8.jpg",
    thumbnail: "/img/gallery/gallery8.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 300
},
]

export default () => {
    return(<>
        <style>{`
        .ReactGridGallery_tile {
            border: solid 10px #fff;
        }
            @media(min-width: 768px) {
                .ReactGridGallery_tile {
                    
                    width: 25% !important;
                    overflow: hidden;
                }
            }
            @media(max-width: 767px) {
                .ReactGridGallery_tile {
                    width: 100% !important;
                }
            }
            .ReactGridGallery_tile-viewport {
                width: 100% !important;
                height: 100% !important;
            }
            .ReactGridGallery_tile-viewport img {
                width: 108% !important;
                height: 100% !important;
                transition: transform .2s;
            }
            .ReactGridGallery_tile-viewport img:hover {
                -ms-transform: scale(1.1); /* IE 9 */
                -webkit-transform: scale(1.1); /* Safari 3-8 */
                transform: scale(1.1);
            }
        `}</style>
        <Container>
            <Row>
                <Col className="text-center">
                    <h2 className="section-title mb-5">GALLERY</h2>
                </Col>
            </Row>
        </Container>

        <Gallery 
            images={IMAGES} 
            margin={0}
        />
        <div style={{clear: 'both'}}></div>
    </>)
}